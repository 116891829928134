import * as React from "react";
import "../styles/main.css";
import { graphql } from "gatsby";

import Layout from "../components/Layout";
import DefaultPage from "../components/DefaultPage";

const FootprintPage = ({ data }) => {
  const page = data.allMarkdownRemark.nodes[0];

  let heading = page.frontmatter.heading;
  let html = page.html;

  return (
    <Layout>
      <DefaultPage heading={heading} html={html} />
    </Layout>
  );
};

export default FootprintPage;

export const query = graphql`
  query getFootprintPageQuery {
    allMarkdownRemark(
      filter: {
        frontmatter: { system_name: { eq: "footprint" } }
        fileAbsolutePath: { regex: "/.*_pages.*/" }
      }
    ) {
      nodes {
        frontmatter {
          system_name
          heading
        }
        html
      }
    }
  }
`;
